<template>
  <div id="testReviews">
    <div class="top">
      <div class="bg">
        <div class="context">
          <div>
            <h3>温故知新强化学习，高效畅学标准化知识</h3>
            <p>拓宽学习的渠道，聚焦政策法规、标准和标准化专业书籍等多维度理论知识，以学带测，以测固学，助力标准化专业人员查漏补缺，全面提升个人标准化能力，加快向复合型人才转变。</p>
            <div>
              <el-button color="#0072EF" type="primary" @click="eventClick('https://tgik6tfkz3.jiandaoyun.com/f/6295b652f2fcae0009372016', 'open', 'test')">前往测评</el-button>
              <el-button color="#0072EF" type="primary" plain @click="eventClick('/testDescription', 'open', 0)"><svg-icon icon-class="manual"></svg-icon>操作说明</el-button>
              <el-button color="#0072EF" type="primary" plain @click="eventClick('https://tgik6tfkz3.jiandaoyun.com/f/629474fd377bb40008534ec2', 'open', 'grades')"><svg-icon icon-class="trophy"></svg-icon>成绩查询</el-button>
            </div>
          </div>
          <img src="../../assets/img/cp_01.png">
        </div>
      </div>
    </div>
    <div class="center">
      <div class="context">
        <h3>测评流程</h3>
        <div class="inner">
          <img src="../../assets/img/cp_02.png" />
          <div class="item_list">
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <span>{{ index + 1 }}</span>
              <div>
                <h5>{{ item.title }}</h5>
                <p>{{ item.tip }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <h3>标准化测评为你敲开标准化知识大门</h3>
      <p>为标准化专业人员快速填补知识空缺，了解自身定位提供有力的支持</p>
    </div>
    <ChooseModal :visible="visible" :type="type" @close="closeClick" />
  </div>
</template>

<script>
import ChooseModal from './components/chooseModal.vue'
export default {
  components: {
    ChooseModal
  },
  data() {
    return {
      dataList: [
        { title: '考生登录', tip: '点击上方“前往测评”按钮，进入测评系统，作答前填写个人和单位信息' },
        { title: '参加测评', tip: '填写信息后开始作答，在规定时间内完成作答后点击“提交”，完成测评' },
        { title: '成绩查询', tip: '提交考卷后，按照提示点击链接或点击上方“成绩查询”按钮，输入个人信息查询测评成绩' },
      ],
      visible: false,
      type: null
    }
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  },
  methods: {
    eventClick(url, type, tip) {
      if(type == 'open') {
        if(!this.$store.state.loginIn && tip) return this.$store.commit('open_type', 1)
        if(tip) {
          this.type = tip;
          this.visible = true;
        }else window.open(url)
      }else {
        this.$router.push(url)
      }
    },
    closeClick() {
      this.type = null;
      this.visible = false;
    }
  }
}
</script>

<style lang='scss'>
  #testReviews {
    .top {
      width: 100%;
      height: 415px;
      background: #fff;
      margin-bottom: 10px;

      .bg {
        background: linear-gradient(180deg,rgba(0,114,239,0.20), rgba(255,255,255,0.00));
      }

      .context {
        padding: 95px 40px 0;
        width: 1200px;
        display: flex;
        margin: 0 auto;

        & > div {
          width: 640px;
          flex-shrink: 0;
          margin-right: 60px;
          font-size: 16px;
          color: #1d2129;

          & > h3 {
            font-size: 32px;
            color: #3f3d56;
            line-height: 45px;
            margin-bottom: 20px;
          }

          & > p {
            line-height: 28px;
            margin-bottom: 50px;
            width: 624px;
          }

          & > div {
            .el-button {
              width: 124px;
              height: 50px;
              border-radius: 4px;
              margin-right: 15px;
              font-size: 16px;
              font-weight: 600;

              &:hover,
              &:active,
              &:focus {
                .svg-icon {
                  color: #fff;
                }
              }

              .svg-icon {
                width: 14px;
                height: 14px;
                color: #0072EF;
                margin-right: 6px;
              }
            }
          }
        }

        & > img {
          width: 380px;
          height: 320px;
        }

      }
    }

    .center {
      height: 435px;
      background: #ffffff;

      .context {
        width: 1200px;
        margin: 0 auto;
        padding: 40px 10px 70px;

        & > h3 {
          line-height: 45px;
          font-size: 32px;
          color: #3f3d56;
          margin-bottom: 30px;
          text-align: center;
        }

        .inner {
         display: flex;

         & > img {
          width: 280px;
          height: 250px;
          margin-right: 110px;
         }

         .item_list {
          margin-top: 14px;

          .item {
            display: flex;
            margin-bottom: 37px;

            &:last-child {
              margin-bottom: 0;
            }

            & > span {
              display: block;
              width: 24px;
              height: 24px;
              background: #0072ef;
              border-radius: 50%;
              font-size: 14px;
              color: #ffffff;
              line-height: 24px;
              text-align: center;
              margin-right: 20px;
            }

            & > div {
              & > h5 {
                font-size: 16px;
                color: #1d2129;
                line-height: 22px;
                margin-bottom: 5px;
              }

              & > p {
                font-size: 14px;
                color: #4e5969;
                line-height: 20px;
              }
            }
          }
         }
        }
      }
    }

    .bottom {
      height: 187px;
      background: #0072ef;
      padding: 50px 0 60px;
      text-align: center;
      color: #fff;

      & > h3 {
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 10px;
      }

      & > p {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: rgba(255,255,255,0.6)
      }
    }
  }
</style>